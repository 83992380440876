<template>
  <div class="flex flex-col font-Inter px-10">
    <Loading v-if="loadingFull" />
    <div class="lg:flex lg:items-center lg:justify-between mb-3">
      <div class="flex-1 min-w-0 flex justify-between py-4">
        <div
          class="mt-1 flex flex-row justify-center items-center sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6"
        >
          <h2
            class="text-xl font-medium leading-7 text-gray-900 sm:text-2xl sm:truncate uppercase"
          >
            Facture d'achat N° {{ bill.bill_no }}
          </h2>
        </div>
        <div>
          <!--  <el-button icon="el-icon-message" plain>Envoi email</el-button> -->
          <el-button
            v-if="bill.status === 'DRAFT'"
            type="primary"
            @click="handleConfirmed"
            >Confirmer</el-button
          >

          <el-button
            @click="handlePaymentBill"
            v-if="bill.status === 'OPEND'"
            type="primary"
            icon="el-icon-money"
            >Régler la facture</el-button
          >

          <el-dropdown
            style="margin-left: 10px"
            @command="handleCommand"
            trigger="click"
          >
            <el-button>
              Action<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-if="bill.status === 'DRAFT'" command="update">
                <i class="el-icon-edit"></i>
                Modifier
              </el-dropdown-item>
              <el-dropdown-item command="sale">
                <i class="el-icon-document-copy"></i>
                Créer une facture de vente
              </el-dropdown-item>
              <el-dropdown-item command="cancel">
                <i class="el-icon-delete"></i>
                Annuler
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <div class="mb-10">
      <div class="flex flex-col mx-auto shadow bg-white p-5 ">
        <el-tabs v-model="tabName" class="px-4">
          <el-tab-pane label="Aperçu" name="BILL">
            <div v-if="tabName === 'BILL'">
              <div class="flex justify-between items-start">
                <div class="flex flex-col w-1/2">
                  <h1
                    class="text-xl font-medium leading-7 text-gray-900 sm:text-2xl sm:truncate mb-5 uppercase"
                  >
                    {{ bill.contact_name }}
                  </h1>
                  <div class="flex flex-col bg-gray-100 rounded-md w-2/3 p-3">
                    <div class="flex justify-between mb-2">
                      <span class="text-gray-500">N°</span>
                      <span class="text-blue-500">{{ bill.contact_no }}</span>
                    </div>
                    <div class="flex justify-between mb-2">
                      <span class="text-gray-500">Téléphone</span>
                      <span>{{ bill.phone }}</span>
                    </div>
                    <div class="flex justify-between mb-2">
                      <span class="text-gray-500">Email</span>
                      <span>{{ bill.email }}</span>
                    </div>
                    <div class="flex justify-between">
                      <span class="text-gray-500">Adresse</span>
                      <span>{{ bill.contact_street }}</span>
                    </div>
                    <div class="flex justify-between">
                      <span class="text-gray-500">Correspondant</span>
                      <span
                        >{{ bill.contact_firstname }}
                        {{ bill.contact_lastname }}</span
                      >
                    </div>
                  </div>
                </div>
                <div class="flex flex-col justify-end items-end w-1/2">
                  <div
                    class="text-3xl font-medium text-gray-900 sm:text-3xl sm:truncatemt-2 my-2"
                  >
                    <div
                      class="text-xl text-red-600 font-medium leading-7 text-gray-900 sm:text-2xl sm:truncate mb-5 uppercase"
                      v-if="bill.status === 'OPEND'"
                    >
                      Total dû :
                      {{ (bill.total - bill.avance) | moneyFilter }}
                      {{bill.currency}}
                    </div>
                    <div
                      class="text-xl font-medium leading-7 text-gray-900 sm:text-2xl sm:truncate mb-5 uppercase"
                      v-else
                    >
                      Total :
                      {{ bill.total | moneyFilter }}
                        {{bill.currency}}
                    </div>
                  </div>
                  <div class="flex flex-col w-2/3 p-3">
                    <div class="flex justify-between mb-3">
                      <span class="text-gray-500">Date création :</span>
                      <span>{{ bill.created_at }}</span>
                    </div>
                    <div class="flex justify-between mb-3">
                      <span class="text-gray-500">Date :</span>
                      <span>{{ bill.bill_date }}</span>
                    </div>
                    <div class="flex justify-between mb-3">
                      <span class="text-gray-500">Date d'echéance : </span>
                      <span>{{ bill.due_date }}</span>
                    </div>

                    <div class="flex justify-between mb-3">
                      <span class="text-gray-500">Statut</span>
                      <Status :name="bill.status" />
                    </div>
                    <div class="flex justify-between mb-3">
                      <span class="text-gray-500">Modalité de paiement</span>
                      <div>
                        <span>{{ bill.terms }}</span>
                      </div>
                    </div>
                    <div class="flex justify-between">
                      <span class="text-gray-500 mr-3">Ref.</span>
                      <p>{{ bill.reference }}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="customer-white">
                <el-table :data="bill.items" style="width: 100%" class="mt-10">
                  <el-table-column prop="warehouse_code" label="C.E" width="80">
                    <template slot-scope="{ row }">
                      <span class="text-blue">{{ row.warehouse_code }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="article_no"
                    label="N° ARTICLE"
                    width="160"
                  >
                  </el-table-column>

                  <el-table-column
                    prop="article_name"
                    label="DÉSIGNATION"
                    min-width="250"
                  >
                    <template slot-scope="{ row }">
                      <div class="flex flex-col justify-start">
                        <div class="mb-1">{{ row.article_name }}</div>

                        <p class="text-gray-400">{{ row.description }}</p>
                      </div>
                    </template>
                  </el-table-column>

                  <el-table-column
                    prop="quantity"
                    align="center"
                    label="QUANTITÉ"
                    width="100"
                  >
                    <template slot-scope="{ row }">
                      <div class="input-transparent">
                        <span>{{ row.quantity }}</span>
                      </div>
                    </template>
                  </el-table-column>

                  <el-table-column align="right" label="PRIX" width="140">
                    <template slot-scope="{ row }">
                      <span>{{ row.price | moneyFilter }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column align="center" label="REMISE" width="120">
                    <template slot-scope="{ row }">
                      <span>{{ row.percent_discount }}%</span>
                    </template>
                  </el-table-column>
                  <el-table-column align="center" label="TAXE" width="120">
                    <template slot-scope="{ row }">
                      <span>{{ row.percent_tax }}%</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="price"
                    align="right"
                    label="TOTAL"
                    width="150"
                  >
                    <template slot-scope="{ row }">
                      <span>{{ row.total | moneyFilter }} </span>
                    </template>
                  </el-table-column>
                </el-table>
              </div>

              <div class="flex justify-between items-start mb-2 border-b py-8">
                <div class="flex flex-col">
                  <div class="flex justify-between">
                    <span class="text-gray-500">Note :</span>
                    <p>{{ bill.comment }}</p>
                  </div>
                </div>

                <div class="flex flex-col w-1/4 justify-start">
                  <div class="flex justify-between">
                    <span>Total HT:</span>
                    <span> {{ bill.total_wt | moneyFilter }} </span>
                  </div>
                  <div class="flex justify-between pt-2 mt-4 text-gray-500">
                    <span class="mr-4"
                      >Remise ({{ bill.percent_discount }} %) :</span
                    >
                    <span>{{ bill.discount | moneyFilter }} </span>
                  </div>
                  <div class="flex justify-between mt-4 text-gray-500">
                    <span class="mr-4">Taxe ({{ bill.percent_tax }}%):</span>
                    <span>{{ bill.total_tax | moneyFilter }} </span>
                  </div>
                  <div class="flex justify-between mt-4 text-gray-500">
                    <span class="mr-4">Autre coût:</span>
                    <span>{{ bill.shipping_amount | moneyFilter }} </span>
                  </div>
                  <div
                    class="flex justify-between font-medium leading-7 text-gray-900 py-4 border-b"
                  >
                    <span>Total :</span>
                    <span>
                      {{ bill.total | moneyFilter }}
                      {{ bill.currency }}</span
                    >
                  </div>
                  <div
                    v-if="bill.status === 'OPEND'"
                    class="flex justify-between text-gray-900 py-4"
                  >
                    <span>Avance :</span>
                    <span>{{ bill.avance | moneyFilter }} </span>
                  </div>
                  <div
                    v-if="bill.status === 'OPEND'"
                    class="flex justify-between text-red-700 py-2"
                  >
                    <span>Reste à payer :</span>
                    <span
                      >{{ (bill.total - bill.avance) | moneyFilter }} {{bill.currency}}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="Reglèments effectués" name="PAYMENT">
            <div>
              <el-table
                v-loading="listpaimentLoading"
                :data="paiements"
                style="width: 100%"
              >
                <el-table-column
                  prop="payment_no"
                  label="N° PAIEMENT"
                  width="160"
                >
                  <template slot-scope="{ row }">
                    <span class="text-blue">{{ row.payment_no }}</span>
                  </template>
                </el-table-column>

                <el-table-column prop="payment_no" label="DATE" width="180">
                  <template slot-scope="{ row }">
                    <span>{{ row.date | filterTime }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="reference" label="REFERENCE" width="160">
                </el-table-column>
                <el-table-column label="STATUT" width="200">
                  <template slot-scope="{ row }">
                    <Status :name="row.status" />
                  </template>
                </el-table-column>
                <el-table-column prop="account" label="COMPTE" min-width="250">
                  <template slot-scope="{ row }">
                    <div class="flex flex-col justify-start">
                      <div class="mb-1">{{ row.account_name }}</div>

                      <!-- <p class="text-gray-400">{{ row.account_number }}</p> -->
                    </div>
                  </template>
                </el-table-column>

                <el-table-column
                  prop="quantity"
                  align="right"
                  label="MONTANT"
                  width="250"
                >
                  <template slot-scope="{ row }">
                    <span>{{ row.amount | moneyFilter }} {{user.currency_code}}</span>
                  </template>
                </el-table-column>
                <el-table-column width="120" align="right">
                  <template slot-scope="{ row }">
                    <el-button
                      v-if="row.status === 'PAID'"
                      type="danger"
                      circle
                      size="mini"
                      icon="el-icon-delete"
                      @click="handleDelete(row)"
                    ></el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>

    <!-- List Payment en Bl -->
    <el-dialog
      title="Reglèment facture d'achat"
      :visible.sync="dialogPaymentInvoice"
      width="35%"
      top="4vh"
      :close-on-click-modal="false"
    >
      <el-form
        :model="paymentBill"
        :rules="rules"
        ref="paymentBillForm"
        :label-position="labelPosition"
      >
        <div class="flex">
          <el-form-item class="w-1/2 mr-2" prop="payment_no" label="Numéro ">
            <el-input
              class="w-full"
              v-model="paymentBill.payment_no"
              :readonly="true"
            >
            </el-input>
          </el-form-item>

          <el-form-item class="w-1/2" prop="payment_date" label="Date">
            <el-date-picker
              class="w-full"
              v-model="paymentBill.payment_date"
              type="date"
              placeholder="jj-mm-aaaa"
              format="dd MMMM yyyy"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
        </div>
        <div class="flex">
          <el-form-item
            prop="account_number"
            class="w-1/2 mr-2"
            label="Compte de reception"
          >
            <el-select
              v-model="paymentBill.account_number"
              class="w-full"
              @change="selectAccount"
            >
              <el-option
                v-for="item in accounts"
                :key="item.account_id"
                :label="`${item.account_name}`"
                :value="item.numero"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="amount" p class="w-1/2" label="Montant">
            <el-input-number
              class="w-full"
              :controls="false"
              v-model="paymentBill.amount"
              :min="0"
              :max="bill.total"
            ></el-input-number>
          </el-form-item>
        </div>
        <el-form-item label="Reference">
          <el-input
            placeholder="N° du reçu inscrit "
            v-model="paymentBill.reference"
          />
        </el-form-item>
        <el-form-item label="Note">
          <el-input
            type="textarea"
            :autosize="{ minRows: 3, maxRows: 3 }"
            placeholder="Entrez quelque chose"
            v-model="paymentBill.note"
          />
        </el-form-item>
      </el-form>

      <div slot="footer" class="border-t pt-2 dialog-footer">
        <el-button @click="dialogVisible = false">Annuler</el-button>
        <el-button
          type="primary"
          :loading="paymentLoading"
          :disabled="paymentLoading"
          @click="createPaymentBill('paymentBillForm')"
          >Enregistrer</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { fetchAccounts } from "@/api/account";
import {
  getBillByID,
  generatePaymentNo,
  createPaymentSingleBill,
  getItemsPaymentForBill,
  cancelPaymentBill,
  //copyBill,
  opendBill,
  cancelBill,
} from "@/api/purchase";
import { parseTime, formatDate } from "@/utils";
import { toThousandFilter } from "@/Filters";
import Status from "@/components/Status";
import Loading from "@/components/Loanding/Full";
import {mapGetters} from "vuex";
export default {
  name: "BillFiche",
  filters: {
    filterTime: parseTime,
    filterDate: formatDate,
    moneyFilter: toThousandFilter,
  },
  components: { Status, Loading },
  data() {
    return {
      loadingFull: false,
      dialogPayment: false,
      labelPosition: "top",
      loadingRP: false,
      paymentLoading: false,
      dialogPaymentInvoice: false,
      tabName: "BILL",
      type: "BILL",
      bill: {
        bill_id: "",
        percent_amount: 0,
        percent_taxe: 0,
        bill_no: "",
        avance: 0,
        contact_first_name: "",
        contact_id: "",
        contact_last_name: "",
        contact_organisation_name: "",
        due_date: "",
        due_days: 0,
        reference: "",
        shipping_charge: 0,
        status: "",
        total: 0,
        terms: "",
        total_wt: 0,
        items: [],
      },
      accounts: [],
      listpaimentLoading: false,
      paymentBill: {
        supplier_id: "",
        note: "",
        payment_date: formatDate(new Date()),
        payment_no: "",
        account_number: "",
        account_name: "",
        amount: 0,
        reference: "",
        items: [],
      },
      updatePrice: [],
      paiements: [],
      listQuery: {
        total: 0,
        page: 1,
        size: 20,
        sort: "desc",
        sortDir: "createdAt",
      },
      rules: {
        payment_date: [
          {
            required: true,
            message: "La date est obligatoire",
            trigger: "change",
          },
        ],
        payment_no: [
          {
            required: true,
            message: "La numéro paiement est obligatoire",
            trigger: "bluer",
          },
        ],

        amount: [
          {
            required: true,
            message: "Le montant est obligatoire",
            trigger: "blur",
          },
        ],
        account_number: [
          {
            required: true,
            message: "Le compte est obligatoire",
            trigger: "change",
          },
        ],
      },
    };
  },
  computed: {
      ...mapGetters(["user"]),
  },
  watch: {
    tabName(val) {
      this.$router.push(`${this.$route.path}?tab=${val}`);
    },
  },
  created() {
    const tab = this.$route.query.tab;
    if (tab) {
      this.tabName = tab;
    }
  },

  mounted() {
    this.fetchData();
    this.fetchPaymentData();
    this.getAccounts();
  },
  methods: {
    async fetchData() {
      const id = this.$route.params && this.$route.params.id;
      this.loadingFull = true;
      await getBillByID(id, this.type)
        .then((res) => {
          setTimeout(() => {
            this.bill = Object.assign({}, res.data);
            this.loadingFull = false;
          }, 1.5 * 1000);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async fetchPaymentData() {
      const id = this.$route.params && this.$route.params.id;
      this.listpaimentLoading = true;
      await getItemsPaymentForBill(id, this.listQuery).then((res) => {
        setTimeout(() => {
          this.paiements = res.data.items;
          this.listQuery.total = parseInt(res.data.total_items);
          this.listQuery.page = parseInt(res.data.current_page) + 1;
          this.listLoading = false;
          this.listpaimentLoading = false;
        }, 1.5 * 1000);
      });
    },

    async getAccounts() {
      await fetchAccounts()
        .then((res) => {
          this.accounts = res.data;
        })
        .catch((err) => {
          this.$message({
            message: err.data.response,
            type: "danger",
          });
        });
    },

    async nextPaymentNo() {
      await generatePaymentNo()
        .then((res) => {
          this.paymentBill.payment_no = res.data.numero;
        })
        .catch((err) => {
          this.$message({
            message: err.data.response,
            type: "danger",
          });
        });
    },
    handleDelete(row) {
      this.$confirm(
        "Vous souhaitez vraimment supprimer le document ?",
        `Supprimer le  Paiement N° ${row.payment_no}`,
        {
          distinguishCancelAndClose: true,
          dangerouslyUseHTMLString: true,
          confirmButtonText: "Supprimer",
          showClose: false,
          type: "warning",
          closeOnClickModal: false,
          cancelButtonText: "Annuler",
          beforeClose: (action, instance, done) => {
            if (action === "confirm") {
              instance.confirmButtonLoading = true;
              instance.confirmButtonText = "encours...";
              cancelPaymentBill(row.payment_id)
                .then(() => {
                  setTimeout(() => {
                    done();
                    setTimeout(() => {
                      instance.confirmButtonLoading = false;
                    }, 300);
                    const item = this.paiements.find(
                      (el) => el.payment_id === row.payment_id
                    );
                    item.status = "CANCELED";

                    this.$message({
                      type: "success",
                      message: " Le paiement annulé avec success",
                    });
                  }, 1.5 * 1000);
                })
                .catch((err) => {
                  instance.confirmButtonLoading = false;
                  if (err.data.status === 400) {
                    this.$message({
                      message:
                        "Veuillez renseigner les informations obligatoire et les articles",
                      type: "warning",
                    });
                  } else if (
                    err.data.status === 409 ||
                    err.data.status === 404
                  ) {
                    this.$message({
                      message: err.data.message,
                      type: "danger",
                    });
                  } else {
                    this.$message({
                      message:
                        "Erreur serveur ! Merci de contacter l'administrateur",
                      type: "danger",
                    });
                  }
                });
            } else {
              done();
            }
          },
        }
      ).then(() => {});
    },
    resetPaymentBill() {
      this.paymentBill = {
        supplier_id: "",
        note: "",
        payment_date: formatDate(new Date()),
        payment_no: "",
        amount: 0,
        reference: "",
        account_number: "",
        account_name: "",
        items: [],
      };
    },
    handlePaymentBill() {
      this.resetPaymentBill();
      this.nextPaymentNo();
      this.paymentBill.supplier_id = this.bill.contact_id;

      const accountDefault = this.accounts.find(
        (el) => el.has_primary === true
      );
      this.paymentBill.account_number = accountDefault.numero;
      this.paymentBill.account_name = accountDefault.account_name;
      this.paymentBill.amount = this.bill.total - this.bill.avance;
      this.$nextTick(() => {
        this.$refs["paymentBillForm"].clearValidate();
      });
      this.dialogPaymentInvoice = true;
    },

    selectAccount(id) {
      const account = this.accounts.find((el) => el.numero === id);
      this.paymentBill.account_name = account.account_name;
    },

    createPaymentBill(paymentBillForm) {
      this.$refs[paymentBillForm].validate((valid) => {
        if (valid) {
          if (this.paymentBill.amount > this.bill.total - this.bill.avance) {
            this.$message({
              showClose: false,
              message: "Les montant restant à payer est different total saisi",
              type: "error",
            });
          } else {
            this.paymentLoading = true;
            this.paymentBill.items = [];
            this.paymentBill.items.push({
              bill_id: this.bill.bill_id,
              amount: this.paymentBill.amount,
            });

            createPaymentSingleBill(this.paymentBill)
              .then(() => {
                setTimeout(() => {
                  this.bill.avance += this.paymentBill.amount;
                  if (this.bill.avance === this.bill.total) {
                    this.bill.status = "PAID";
                  }
                  this.fetchPaymentData();
                  this.paymentLoading = false;
                  this.dialogPaymentInvoice = false;
                }, 1.5 * 1000);
              })
              .catch((err) => {
                this.paymentLoading = false;
                if (err.data.status === 400) {
                  this.$message({
                    message:
                      "Veuillez renseigner les informations obligatoire et les articles",
                    type: "warning",
                  });
                } else if (err.data.status === 500) {
                  this.$message({
                    message: "Erreur serveur",
                    type: "danger",
                  });
                } else {
                  this.$message({
                    message: err.data.message,
                    type: "warning",
                  });
                }
              });
          }
        }
      });
    },
    handleSuccess() {
      this.bill.status = "CANCELED";
    },
    handleCanceledBill() {
      const h = this.$createElement;
      this.$msgbox({
        title: "Facture N° " + this.bill.bill_no,
        message: h("div", null, [
          h("span", null, " Voulez vous vraiment annuler la facture d'achat ?"),
        ]),
        showCancelButton: true,
        showClose: false,
        confirmButtonText: "Confirmer",
        cancelButtonText: "Annuler",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "encours...";
            cancelBill(this.bill.bill_id, this.type)
              .then(() => {
                setTimeout(() => {
                  setTimeout(() => {
                    instance.confirmButtonLoading = false;
                  }, 300);

                  this.bill.status = "CANCELED";

                  this.$message({
                    type: "success",
                    message: "La facture d'achat a été annulée",
                  });
                  done();
                }, 1.5 * 1000);
              })
              .catch((err) => {
                setTimeout(() => {
                  this.$message({
                    message: err.data.message,
                    type: "error",
                  });
                  instance.confirmButtonLoading = false;
                  instance.confirmButtonText = "Confirmer";
                }, 1.5 * 1000);
              });
          } else {
            done();
          }
        },
      }).then(() => {});
    },

    handleConfirmed() {
      const h = this.$createElement;
      this.$msgbox({
        title: "Facture N° " + this.bill.bill_no,
        message: h("div", null, [
          h(
            "span",
            null,
            " Voulez vous vraiment confirmer la facture d'achat ?"
          ),
        ]),
        showCancelButton: true,
        showClose: false,
        confirmButtonText: "Confirmer",
        cancelButtonText: "Annuler",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "encours...";
            opendBill(this.bill.bill_id, this.type)
              .then(() => {
                setTimeout(() => {
                  setTimeout(() => {
                    instance.confirmButtonLoading = false;
                  }, 300);

                  this.bill.status = "OPEND";

                  this.$message({
                    type: "success",
                    message: "La facture d'achat a été annulée",
                  });
                  done();
                }, 1.5 * 1000);
              })
              .catch((err) => {
                setTimeout(() => {
                  this.$message({
                    message: err.data.message,
                    type: "error",
                  });
                  instance.confirmButtonLoading = false;
                  instance.confirmButtonText = "Confirmer";
                }, 1.5 * 1000);
              });
          } else {
            done();
          }
        },
      }).then(() => {});
    },
    handleCommand(e) {

      if (e === "cancel") {
        this.handleCanceledBill();
      }

      if (e === "update") {
        this.$router.push(`/purchases/bills/update/${this.bill.bill_id}`);
      }

      if (e === 'sale') {
       this.$router.push(`/sales/invoice/transformer/${this.bill.bill_id}`);

      }    /*   if (e.copy) {
        this.handleCopyill(e.copy);
      }
     */
    },
  },
};
</script>

<style></style>
